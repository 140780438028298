module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"lang":"*","repositoryName":"wooclap-content","promptForAccessToken":true,"apiEndpoint":"https://wooclap-content.cdn.prismic.io/api/v2","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wooclap","short_name":"Wooclap","start_url":"/?utm_source=standalone","lang":"en","description":"A collaborative platform for your classes, conferences and training sessions. Intuitive and simple, Wooclap makes your presentations interactive.","background_color":"#1B2B3D","theme_color":"#1B2B3D","display":"standalone","localize":[{"start_url":"/de/","lang":"de","name":"Wooclap","short_name":"Wooclap","description":"Eine kollaborative Plattform für Ihre Kurse, Konferenzen und Schulungen. Intuitiv und einfach, Wooclap gestaltet Ihre Präsentationen interaktiv."},{"start_url":"/es/","lang":"es","name":"Wooclap","short_name":"Wooclap","description":"Una plataforma de colaboración para sus clases, conferencias y cursos de formación. Intuitivo y simple, Wooclap hace que sus presentaciones sean más interactivas."},{"start_url":"/it/","lang":"it","name":"Wooclap","short_name":"Wooclap","description":"Una piattaforma collaborativa per le tue lezioni, conferenze e corsi di formazione. Intuitivo e semplice, Wooclap ti permette di rendere le tue presentazioni interattive."},{"start_url":"/fr/","lang":"fr","name":"Wooclap","short_name":"Wooclap","description":"Le système de vote interactif qui révolutionne la manière d'enseigner. Plutôt que de combattre les smartphones, Wooclap les transforme en un outil d'apprentissage exceptionnel."}],"icons":[{"src":"https://www.wooclap.com/images/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"https://www.wooclap.com/images/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"https://www.wooclap.com/images/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"https://www.wooclap.com/images/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
